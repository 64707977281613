'use client'; // Error components must be Client Components

import { ParsedText } from '@/app/components/ParsedText';
import { EventTypes, useLogEvent } from '@/app/hooks/useLogEvent';
import { useUpdateLead } from '@/app/hooks/useUpdateLead';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { handleZendeskShow } from '@/lib/utils';
import { Button } from '@/v2Components/Button/Button';
import { useParams } from 'next/navigation';
import { useEffect } from 'react';
import { Hero } from './components/Hero';
import { PageLayout } from './components/PageLayout';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const params: { partner: string } = useParams();
  const { t } = useTranslation();
  const logError = useLogEvent(EventTypes.ERROR);
  const updateLead = useUpdateLead(params.partner);
  const partnerSettings = getPartnerSettings(params.partner);
  const refreshPage = () => {
    reset();
  };

  useEffect(() => {
    logError(error);
    console.error(error);
    updateLead({
      data: {
        error: {
          event: 'Error boundary hit',
          errorMesage: error,
          url: window.location,
        },
      },
    });
    handleZendeskShow({ minWidth: 768, timeout: 2000 });
  }, [error]);

  return (
    <PageLayout partner={params.partner}>
      <PageLayout.Left heading={t('pp-application')} partner={params.partner}>
        <h1 className="text-lg text-core-8 mb-8">
          {t('pp-error-boundary-header')}
        </h1>
        <p className="text-core-7 mb-8 text-sm">
          <ParsedText
            htmlString={t('pp-error-boundary-copy', {
              contactPhoneNumber: partnerSettings?.contactPhoneNumber ?? '',
            })}
          />
        </p>
        <Button
          type="button"
          onClick={refreshPage}
          mixpanelButtonText={'Page refresh after error boundary hit'}
          id="businessDetailStep1"
        >
          {t('pp-error-boundary-button')}
        </Button>
      </PageLayout.Left>

      <PageLayout.Right hiddenOnMobile={true}>
        <Hero />
      </PageLayout.Right>
    </PageLayout>
  );
}
